//
// components-rtl.scss
//

// buttons

.button-list{
  .btn{
    margin-right: 0;
    margin-left: 5px;
  }
}

// icons

.icons-list-demo{
  i{
    margin-left: 12px;
    margin-right: 0;
  }
}


// Inbox-widget

.inbox-widget {
  .inbox-item {
    .inbox-item-img {
      float: right;
      margin-left: 15px;
      margin-right: 0;
    }

    .inbox-item-date {
      right: auto;
      left: 5px;
    }
  }
}

// avatar group img

.avatar-group {
  .avatar-group-item {
    margin: 0 -12px 10px 0;
  }
}


// Custom-radio

.checkbox {
  label {
    padding-right: 8px;
    padding-left: 0;

    &::before {
      left: auto;
      right: 0;
      margin-left: 0;
      margin-right: -18px;
    }

    &::after {
      left: auto;
      right: 0;
      margin-right: -18px;
      margin-left: 0;
      padding-left: 0;
      padding-right: 3px;
    }
  }

  input[type="checkbox"]:checked+label {
    &::after {
      left: auto;
      right: 7px;
      transform: rotate(45deg);
    }
  }
}

.checkbox.checkbox-single {
  label {
    &:before {
      margin-right: 0;
    }

    &:after {
      margin-right: 0;
    }
  }
}


// custom-radio

.radio {
  label {
    padding-left: 0;
    padding-right: 8px;

    &::before {
      left: auto;
      right: 0;
      margin-left: 0;
      margin-right: -18px;
    }

    &::after {
      left: 0;
      right: 6px;
      margin-left: 0;
      margin-right: -20px;
    }
  }
}

// search box
.search-box {
  .form-control{
    padding-right: 14px;
    padding-left: 36px;
  }

  .search-icon{
    right: auto;
    left: 12px;
  }
}